import { Seo } from "~/components";
import { NotFoundPage } from "~/page-components";
import React from "react";

export default function NotFound() {
  return (
    <>
      <Seo />
      <NotFoundPage />
    </>
  );
}
